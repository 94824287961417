import React from 'react';
import { Layout } from 'antd';
import Header from '../../components/Header/index';
import Content from '../../components/Content';
import './index.less'

const BasicLayout: React.FC = () => (
  <Layout className='openapi-doc-layout'>
    <Header />
    <Content />
  </Layout>
);

export default BasicLayout;