import { lazy, ReactNode, Suspense } from 'react';
import { useRoutes, Navigate, RouteObject } from 'react-router-dom'
import BasicLayout from '../layouts/BasicLayout'

const NotFound = lazy(() => import('../pages/result/404')) // 找不到页面
const Doc = lazy(() => import('../pages/doc/index')) // api文档页
const Debug = lazy(() => import('../pages/debug/index')) // api在线调试页

const lazyLoad = (children: ReactNode, meta?: any): ReactNode => {
    const element = (<Suspense>
        {children}
    </Suspense>)
    return element
}

const routes = [
    {
        path: '/',
        redirect: '/doc'
    },
    {
        element: <BasicLayout />,
        children: [
            {
                path: '/doc',
                element: <Doc />
            },
            {
                path: '/debug',
                element: <Debug />,
                meta: {
                    title: '示例代码中心'
                }
            }
        ]
    },
    {
        path: '/404',
        element: <NotFound />
    },
    {
        path: '*',
        redirect: '/404'
    }
]

// 路由拦截
const onRouteBefore = (meta: any) => {
    // 动态修改页面title
    if (meta && meta?.title !== undefined) {
        document.title = meta.title
    }
}

// 转换路由数据格式
const transformRoutes = (routeList: any[]) => {
    const list: RouteObject[] = []
    routeList.forEach(route => {
        const obj = { ...route }
        if (obj.redirect) {
            obj.element = <Navigate to={obj.redirect} replace={true} />
        } else if (obj.element) {
            obj.element = lazyLoad(obj.element, obj.meta || {})
        }
        delete obj.redirect
        delete obj.meta
        if (obj.children) {
            obj.children = transformRoutes(obj.children)
        }
        list.push(obj)
    })
    return list
}

const Router = () => useRoutes(transformRoutes(routes))


export { Router } 