import React from 'react';
import './index.less';
import { Outlet } from "react-router-dom";

const Content: React.FC = () => {
  return (
      <div className="content-container">
        {/* 显示匹配到的子组件 */}
        <Outlet />
      </div>
  );
}

export default Content;