import React from 'react'
import './index.less'

const Header: React.FC = () => {
  return (
    <div className='openapi-doc-header'>
      <div className='openapi-doc-header-left'>
        <img src={require("../../assets/logo.png")} alt="" />
      </div>
      <div className='openapi-doc-header-right'>
        {/* <span>登录</span> */}
      </div>
    </div>
  );
}

export default Header;